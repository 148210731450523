import React, { useState } from 'react'
import { integrationTabs } from 'constants/integrationTabs'

function Switch(props) {
    const { setMessageQueue } = props
    const [tabStates, setTabStates] = useState({})
    const [activeTab, setActiveTab] = useState(Object.keys(integrationTabs)[0])
    const Component = integrationTabs[activeTab]
    const updateTabStates = value => {
        const tempStates = { ...tabStates }
        tempStates[activeTab] = value
        setTabStates(tempStates)
    }
    
    return (
        <div className="mb-4">
            {/* <Tab props={Object.keys(SubMenusOneConstants)[0]}/> */}
            <div className="d-flex justify-content-around tab-section-border mb-3">
            {
                Object.keys(integrationTabs).map((tab, idx) => (
                    <div className="d-flex flex-column justify-content-start tabs" key={ idx }>
                        <button
                            type="button"
                            className={ `bg-white pb-3 px-4 border-0 tab${ tab === activeTab && "-active"}` }
                            onClick={e => setActiveTab(e.target.innerText)}
                        >
                            <span className="px-1">{ tab }</span>
                        </button>
                        {
                            tab === activeTab && ( 
                                <div className="bg-white p-0 outline-active"></div>
                            )
                        }
                    </div>
                ))
            }
            </div>
            <Component currentState={ tabStates[activeTab] } setCurrentState={ updateTabStates } setMessageQueue={ setMessageQueue } />
        </div>
    )
}

export default Switch
