import Papa from "papaparse";

const parseCsvfile = (file, callbackFn) => {
    if (!file) {
        console.error("No file provided for parsing.");
        return;
    }

    const reader = new FileReader();

    reader.onload = async ({ target }) => {
        try {
            const csv = Papa.parse(target.result, {
                header: true,          // Assumes the file has a header row
                skipEmptyLines: true,  // Skips empty lines in the CSV
            });

            if (!csv?.data || csv?.data.length === 0) {
                console.error("No data parsed from the CSV file.");
                return;
            }

            console.log("Parsed CSV data:", csv.data);  // Debugging purpose
            callbackFn(csv.data);  // Pass the parsed data to the callback function

        } catch (error) {
            console.error("Error parsing CSV file:", error);
        }
    };

    reader.onerror = (error) => {
        console.error("Error reading the file:", error);
    };

    reader.readAsText(file);
};

export { parseCsvfile };
