import {DataTable} from "../../../components";
import {pubmaticDomainsWhitelistHead} from "../../../constants/tableConstants";
import React, {useEffect} from "react";
import Request from '../../../services/Request'
import {pathFor} from "../../../constants/apiPaths";
import {useHistory} from "react-router-dom";

function PubmaticDomainsWhitelist(props) {
    const history = useHistory();
    const {setMessageQueue} = props
    const [domainsWhitelist, setDomainsWhitelist] = React.useState([])


    const fetchDomainsWhitelist = async () => {
        try {
            const {statusCode, jsonResponse} = await Request(pathFor.pubmaticDomainsWhitelist, {}, {}, "GET")
            if (statusCode === 200) {
                const {global_domains_whitelist} = jsonResponse
                setDomainsWhitelist(global_domains_whitelist)
            } else if (statusCode === 401) {
                setMessageQueue([jsonResponse])
            } else {
                setMessageQueue([{"error": "Unable to fetch data."}])
            }
        } catch (err) {
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }

    useEffect(async () => {
        await fetchDomainsWhitelist()
    }, [])

    return (
        <div>
            <div className="d-flex mb-3 justify-content-between">
                <p className="operation-table">All Domains Whitelist</p>
                <span className="d-flex">
                    <button
                        type="button"
                        className="btn bg--primary text--white px-3 ml-3"
                        onClick={() => history.push(history.location.pathname + '/createDomainsWhitelist')}
                    >
                        Create Domains Whitelist
                    </button>
                </span>
            </div>
            <DataTable tableData={{tableHead: pubmaticDomainsWhitelistHead, tableBody: domainsWhitelist}} infoHead={true}
                       setMessageQueue={setMessageQueue}/>
        </div>
    )
}

export default PubmaticDomainsWhitelist
