import PubmaticReports from "../app/Dashboard/Integrations/PubmaticReports";
import CustomSegmentRuleTable from "../app/Dashboard/Integrations/SwitchTabs/CustomSegmentRuleTable";
import PubmaticDomainsWhitelist from "../app/Dashboard/Integrations/PubmaticDomainsWhitelist";
import PubmaticURLsWhitelist from "../app/Dashboard/Integrations/PubmaticURLsWhitelist";

const tabConstants = {
    "Segments": CustomSegmentRuleTable,
    "Reports": PubmaticReports,
    "Domains Whitelist": PubmaticDomainsWhitelist,
    "URLs Whitelist": PubmaticURLsWhitelist,
}


export {tabConstants}
