import { capitalise } from 'utils/common'
import Anchor from '../Anchor'
import TruncatedText from '../TruncatedText'
import { calculateBytes, calculateThousands } from './demarcations'
import moment from 'moment'


const displayText = (tr, th, csv) => {
    let text = ""
    let val = tr[th.key]
    let truncateAt = th.truncateAt || 100

    if (th.prefix && val >= 0) text += th.prefix
    if (th.isBoolean) {
        val = val ? "Yes" : th.default || "No"
    } if (th.isStatus) {
        val = val === true ? "Active" : val === false ? "Inactive" : "-"
    } else if (th.isBytes) {
        val = calculateBytes(val)
    } else if (th.inThousands) {
        val = calculateThousands(val)
    } else if (!csv && th.key === "url") {
        return <Anchor url={ val } />
    } else if (!csv && typeof val === "string" && val.length > truncateAt) {
        return <TruncatedText value={ val } length={ truncateAt } />
    } else if (th.isInt) {
        val = val ? `${val}` : "0"
    } else if (typeof val === "number") {
        val = val ? `${val.toFixed(2)}` : "0"
    } else {
        val = !th.actions ? val || th.default : null
    }
    if (!val?.length) return "-"

    text += val
    if (th.suffix) text += ` ${th.suffix}`
    if (th.capitalise) text = capitalise(text)
    if (th.isRelativeTime) text = moment.utc(val).fromNow()

    return text
}


export { displayText }
