import AllReports from "app/Dashboard/Reports/AllReports"
import DomainReport from "app/Dashboard/Reports/DomainReport"
import TaxonomyReport from "app/Dashboard/Reports/TaxonomyReport"
import IntentReport from "app/Dashboard/Reports/IntentReport"
import ClientReport from "app/Dashboard/Reports/ClientReport"
import CustomDatasetReport from "app/Dashboard/Reports/CustomDatasetReport"


const tabConstants = {
    "All reports": AllReports,
    "Domain report": DomainReport,
    "Taxonomy report": TaxonomyReport,
    "Intent report": IntentReport,
    "Client report": ClientReport,
    "Custom dataset report": CustomDatasetReport
}


export { tabConstants }
