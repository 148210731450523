import React, { useEffect, useState } from 'react';
import FileUploader from './Components/FileUploader';
import { parseCsvfile } from './Components/parseFiles';
import { validateDomain } from './Components/validations';
import { compareArrays, pluralise } from './Components/common';
import useSnackbar from './Components/useSnackbar';

function AdvertiserDomains(props) {
    const setMessage = useSnackbar()
    const { extras, setExtras } = props
    const [whitelistDomains, setWhitelistDomains] = useState("")
    const [blacklistDomains, setBlacklistDomains] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    // Initialize domains from props
    useEffect(() => {
        if (extras) {
            // Set whitelist domains
            if (extras.domains_whitelist) {
                setWhitelistDomains(extras.domains_whitelist);
            }
            
            // Set blacklist domains
            if (extras.domains_blacklist) {
                setBlacklistDomains(extras.domains_blacklist);
            }
        }
    }, [extras]);

    const validateFile = file => file.type !== "text/csv" && {error: "Invalid file type. CSV required."}
    
    const parseCsvData = (csvData, listType) => {
        if (csvData) {
            const colArray = Object.keys(csvData[0])
            if (compareArrays(colArray.map(i => i.toLowerCase()), ["domain"])) {
                const colName = colArray[0]
                // Validate domains and join with newlines
                const validData = csvData
                    .filter(i => validateDomain(i[colName]))
                    .map(i => i[colName])
                    .join("\n")
                
                const lengthDiff = csvData.length - validData.split("\n").length
                
                lengthDiff && setMessage({
                    info: `${lengthDiff} ${pluralise("domain", lengthDiff)} rejected due to incorrect format.`
                })                

                if (listType === 'whitelist') {
                    setWhitelistDomains(validData)
                } else {
                    setBlacklistDomains(validData)
                }
            } else {
                setMessage({error: "Incorrect data column/s. Kindly refer to the downloaded template."})
            }
        }
    }

    // Update extras when domains change
    useEffect(() => {
        const tempExtras = { ...extras }
        // Store domains directly as strings
        tempExtras.domains_whitelist = whitelistDomains;
        tempExtras.domains_blacklist = blacklistDomains;
        setExtras(tempExtras)
    }, [whitelistDomains, blacklistDomains])

    const handleFileUpload = (file, listType) => {
        setErrorMessage("")
        if (file) {
            parseCsvfile(file, (csvData) => parseCsvData(csvData, listType))
        }
    }

    return (
        <div className='mb-responsive'>
            <span className='label-text d-flex justify-content-between pb-0 pt-2 mb-0'>
                Domains
            </span>
            
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <span className='label-text d-flex justify-content-between pb-0 pt-2 mb-0'>
                    Whitelist
                </span>
                <FileUploader 
                    setUploadedFile={(file) => handleFileUpload(file, 'whitelist')} 
                    validateFile={validateFile} 
                    btnLabel="Import" 
                    className="ml-3" 
                    btnClassName="btn text--white px-3 fix-width-btn" 
                    accept=".csv" 
                />
            </div>
            <textarea 
                className='border rounded w-100 p-2'
                rows={3}
                value={whitelistDomains}
                onChange={(e) => setWhitelistDomains(e.target.value)}
                onMouseEnter={() => setErrorMessage("")}
                onMouseLeave={() => errorMessage?.length && setMessage({error: errorMessage})}
            />

            <div className='d-flex justify-content-between align-items-center mb-3 mt-4'>
                <span className='label-text d-flex justify-content-between pb-0 pt-2 mb-0'>
                    Blacklist
                </span>
                <FileUploader 
                    setUploadedFile={(file) => handleFileUpload(file, 'blacklist')} 
                    validateFile={validateFile} 
                    btnLabel="Import" 
                    className="ml-3" 
                    btnClassName="btn text--white px-3 fix-width-btn" 
                    accept=".csv" 
                />
            </div>
            <textarea 
                className='border rounded w-100 p-2'
                rows={3}
                value={blacklistDomains}
                onChange={(e) => setBlacklistDomains(e.target.value)}
                onMouseEnter={() => setErrorMessage("")}
                onMouseLeave={() => errorMessage?.length && setMessage({error: errorMessage})}
            />
        </div>
    )
}

export default AdvertiserDomains;