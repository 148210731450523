import React, { useRef, useState } from 'react'
import useSnackbar from './useSnackbar'
import Btn from './Btn'


function FileUploader(props) {
    const setMessage = useSnackbar()
    const { setUploadedFile, btnLabel, className, btnClassName, validateFile, accept, disabled } = props
    const hiddenFileRef = useRef()
    const [ fileName, setFileName ] = useState("")
  
    const onChange = event => {
        setFileName("")
        setUploadedFile(null)
        const file = event.target.files[0]
        const fileValidationError = validateFile && validateFile(file)

        if (!fileValidationError) {
            setFileName(file.name)
            setUploadedFile(event.target.files[0])
        } else {
            setMessage(fileValidationError)
        }
        event.target.value = ""
    }

    return (
        <div className={ `d-flex ${className}` }>  
            <div className={ `d-${ fileName?.length ? "block" : "none" } filename-display py-2 px-3 border border-right-0 rounded-left` }>
                { fileName }
            </div>
            <Btn onClick={() => { setFileName(""); hiddenFileRef.current.click() }} className={ btnClassName + " cw-3" } disabled={ disabled }>
                { btnLabel }
            </Btn>
            <input type="file"
                ref={ hiddenFileRef }
                onChange={ onChange }
                className="d-none"
                accept={ accept }
            /> 
        </div>
    )
}

export default FileUploader