import jwtDecode from 'jwt-decode'
import dashboardPages from 'constants/dashboardPages'

const getPages = (arr, condArr) => {
    const pages = []

    arr.forEach(item => {
        if (condArr.includes(item.text)) {
            if (item.subItems) {
                item.subMenu = getPages(item.subItems, condArr)
            }
            pages.push(item)
        }
    })

    return pages
}

const generateAccessiblePages = () => {
    const accessToken = localStorage.getItem("ninaToken")
    const decodedToken = jwtDecode(accessToken)
    const componentsAccess = [...decodedToken.components_access, "Integrations","Pubmatic","Equativ",];
    const pages = getPages(dashboardPages, componentsAccess)

    return pages
}

export { generateAccessiblePages }