import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { DataTable } from 'components';
import { customDatasetTableHead } from 'constants/tableConstants';
import { pathFor } from 'constants/apiPaths';
import Request from 'services/Request';
import { ReactComponent as SearchIcon } from 'assets/icons/misc/SearchIcon.svg';

function CustomSegmentRuleTable({ setMessageQueue }) {
    const history = useHistory();
    const [tableBody, setTableBody] = useState([]);
    const [filterBy, setFilterBy] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    const searchFields = ['name', 'providerAudienceId']; // Updated field names

    const filterSegments = (event) => {
        event.preventDefault();
        if (searchValue.trim()) {
            setFilterBy({ key: searchValue.trim(), fields: searchFields });
        }else
        {
            setFilterBy(null);
        }
    };

    useEffect(() => {
        const fetchSegments = async () => {
            console.log('Fetching segments data...');
            try {
                const response = await Request(pathFor.customsegmentrules, {}, {}, "GET");
                console.log('Raw response:', response);
                const { statusCode, jsonResponse } = response;

                if (!jsonResponse) {
                    console.error('Invalid JSON response');
                    setMessageQueue([{ error: 'Invalid server response. Please try again later.' }]);
                    setTableBody([]);
                    return;
                }

                console.log('Status Code:', statusCode);

                if (statusCode === 200) {
                    const { segments } = jsonResponse;  // Updated to match new structure
                    console.log('Fetched data:', segments);

                    if (!segments || !Array.isArray(segments)) {
                        console.error('No segments found or segments is not an array');
                        setMessageQueue([{ error: 'No segments found.' }]);
                        setTableBody([]);
                        return;
                    }

                    const formattedData = segments.map(segment => ({
                        _id: segment._id,
                        name: segment.name, // Updated field name
                        pubmatic_segment_id: (segment.pubmatic_segment_id || 'NA').toString(), // Updated field name
                        enabled: segment.enabled ? "Active" : "Inactive"
                    }));

                    setTableBody(formattedData);
                } else {
                    console.log('Error response:', jsonResponse);
                    setMessageQueue([jsonResponse]);
                    setTableBody([]);
                }
            } catch (error) {
                console.log('Fetch error:', error);
                setMessageQueue([{ error: 'Server error. Try again after sometime.' }]);
                setTableBody([]);
            }
        };

        fetchSegments();
    }, [setMessageQueue]);

    return (
        <div className="p-0">
            <div className="d-flex justify-content-between align-items-top mb-2">
                <p className="operation-table">All Custom Segment Rules</p>
                <span className="d-flex">
                    <form className="search-filter border--light rounded d-flex" onSubmit={filterSegments}>
                        <input
                            placeholder="Search"
                            name="filterValue"
                            className="border-0 outline-none rounded py-2 pl-3 flex-grow-1"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <button type="submit" className="border-0 bg--white p-0">
                            <SearchIcon className="my-2 mr-2 ml-1" />
                        </button>
                    </form>
                    <button
                        type="button"
                        className="btn bg--primary text--white px-3 ml-3"
                        onClick={() => history.push(history.location.pathname + '/addCustomSegmentRule')}
                    >
                        Create Custom Segment Rule
                    </button>
                </span>
            </div>

            <DataTable
                tableData={{
                    tableHead: customDatasetTableHead(),
                    tableBody
                }}
                filterBy={filterBy}
            />
        </div>
    );
}

export default CustomSegmentRuleTable;
