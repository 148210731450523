import React, { useState, useEffect } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Snackbar } from 'components';
import { pathFor } from 'constants/apiPaths';
import Request from 'services/Request';

const SegmentForm = () => {
    const history = useHistory();
    
    // Form state
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(null);
    const [sourceSegmentId, setSourceSegmentId] = useState('');
    const [providerId, setProviderId] = useState('');
    const [pubmaticAccountId, setPubmaticAccountId] = useState('');
    const [segmentPrice, setSegmentPrice] = useState("Enter the price in USD");
    const [enabled, setEnabled] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState(null);
    const [pubmaticSegmentId, setPubmaticSegmentId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [showPrompt, setShowPrompt] = useState(true);

    // Detect edit mode
    const isEdit = history.location.pathname.includes("editSegment");
    const SegmentRuleId = isEdit ? history.location.pathname.split('/').splice(5)[0] : null;

    useEffect(() => {
        if (name || sourceSegmentId || providerId || pubmaticAccountId || segmentPrice > 0 || enabled) {
            setIsFormDirty(true);
        }
    }, [name, sourceSegmentId, providerId, pubmaticAccountId, segmentPrice, enabled]);

    // Handle redirection after successful submission
    useEffect(() => {
        if (redirecting) {
            const timer = setTimeout(() => {
                setShowPrompt(false); // Disable prompt before navigation
                history.push(pathFor.pubmaticHome);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [redirecting, history]);

    // Clear snackbar after timeout
    useEffect(() => {
        let timeoutId;
        if (snackbarMsg) {
            timeoutId = setTimeout(() => {
                setSnackbarMsg(null);
                // Only clear if not redirecting
                if (!redirecting) {
                    setIsSubmitting(false);
                }
            }, 1000);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [snackbarMsg, redirecting]);

    const handleCancel = () => {
        if (!isFormDirty || window.confirm('Are you sure you want to leave? Your changes will be lost.')) {
            setShowPrompt(false); // Disable prompt before navigation
            history.goBack();
        }
    };

    // Fetch data for edit mode
    useEffect(() => {
        const fetchSegmentData = async () => {
            if (isEdit && SegmentRuleId) {
                try {
                    const { statusCode, jsonResponse } = await Request(pathFor.segments, {}, {}, "GET");

                    if (statusCode === 200 && jsonResponse) {
                        const segment = jsonResponse.segments.find(o => o._id === SegmentRuleId);
                        console.log('Segment found:', segment);
                        
                        if (segment) {
                            setName(segment.segment_name);
                            setProviderId(segment.provider_id);
                            setSourceSegmentId(segment.source_segment_id);
                            setPubmaticAccountId(segment.pubmatic_account_id);
                            setSegmentPrice(segment.segment_price);
                            setEnabled(segment.enabled);
                            setPubmaticSegmentId(segment.pubmatic_segment_id);
                            setIsFormDirty(false); // Reset form dirty state after loading data
                        } else {
                            setSnackbarMsg({ error: "Segment not found" });
                        }
                    } else {
                        setSnackbarMsg({ error: "Failed to fetch segment data" });
                    }
                } catch (error) {
                    console.error("Error fetching segment data:", error);
                    setSnackbarMsg({ error: "Error fetching segment data" });
                }
            }
        };

        fetchSegmentData();
    }, [isEdit, SegmentRuleId]);

    const validateForm = () => {
        let isValid = true;
        
        // Reset previous errors
        setNameError(null);
        
        // Validate segment name
        if (!name.trim()) {
            setNameError('Segment Name is required');
            isValid = false;
        }

        // Validate required fields
        if (!sourceSegmentId.trim() || !providerId.trim() || !pubmaticAccountId.trim()) {
            setSnackbarMsg({ error: "Please fill in all required fields" });
            isValid = false;
        }

        // Validate segment price
        if (segmentPrice < 0) {
            setSnackbarMsg({ error: "Segment price cannot be negative" });
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) return;
    
        setIsSubmitting(true);
        setNameError(null);
    
        try {
            // Prepare payload with trimmed values
            const payload = {
                provider_id: providerId.trim(),
                source_segment_id: sourceSegmentId.trim(),
                segment_name: name.trim(),
                pubmatic_account_id: pubmaticAccountId.trim(),
                segment_price: parseFloat(segmentPrice),
                enabled: enabled,
            };
    
            const { statusCode, jsonResponse } = isEdit
                ? await Request(pathFor.editSegmentRules, payload)
                : await Request(pathFor.createSegment, payload);
    
            // Handle both 200 and 201 status codes
            if ([200, 201].includes(statusCode)) {
                setIsFormDirty(false); // Reset form dirty state on successful submission
                setShowPrompt(false); // Disable prompt before showing success message
                // Set success message with correct type
                setSnackbarMsg({
                    success: isEdit
                        ? "Segment updated successfully!"
                        : "Segment created successfully!"
                });
                setRedirecting(true);
            } else if (statusCode === 422) {
                setSnackbarMsg({
                    error: jsonResponse?.errors
                        ? Object.values(jsonResponse.errors)[0][0]
                        : "Validation error occurred"
                });
                setIsSubmitting(false);
            } else {
                setSnackbarMsg({
                    error: jsonResponse?.message || "An error occurred while processing your request"
                });
                setIsSubmitting(false);
            }
        } catch (err) {
            console.error("Form submission error:", err);
            setSnackbarMsg({ error: "Server error. Please try again later." });
            setIsSubmitting(false);
        }
    };

    return (
        <div className="px-4">
            <Prompt
                when={showPrompt && isFormDirty}
                message="Are you sure you want to leave? Your changes will be lost."
            />

            {/* <div className="crumb bg--secondary w-100 py-2 px-3 rounded">
                <span className="mr-2">Create Segments</span>
                <FontAwesomeIcon icon={faCaretRight} className="caret-right" />
                <strong className="ml-2">{isEdit ? "Edit Segment" : "Add New Segment"}</strong>
            </div> */}

            <p className="operation-table my-3">{isEdit ? "EDIT SEGMENT" : "ADD NEW SEGMENT"}</p>

            {snackbarMsg && <Snackbar msg={snackbarMsg} />}

            <form className="col-xl-4 col-lg-5 col-md-6 col-sm-7 col-8 py-1 px-0" onSubmit={handleSubmit}>
                {/* Form fields remain the same */}
                <div className="form-group">
                    <label htmlFor="client-name">
                        <span className="text--secondary">Segment Name*</span>
                    </label>
                    <input 
                        type="text" 
                        className={`form-control ${nameError ? 'is-invalid' : ''}`}
                        id="client-name" 
                        placeholder="Enter the Name" 
                        value={name}
                        onChange={e => { setNameError(null); setName(e.target.value); }} 
                        required
                    />
                    {nameError && <div className="invalid-feedback">{nameError}</div>}
                </div>

                <div className="form-group">
                    <label htmlFor="source-segment-id">
                        <span className="text--secondary">Source Segment ID*</span>
                    </label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="source-segment-id" 
                        placeholder="Enter the Source Segment ID"
                        value={sourceSegmentId}
                        onChange={e => setSourceSegmentId(e.target.value)} 
                        readOnly={isEdit}
                        required 
                    />
                </div>

                {/* <div className="form-group">
                    <label htmlFor="provider-id">
                        <span className="text--secondary">Provider ID*</span>
                    </label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="provider-id" 
                        placeholder="Enter the Provider ID"
                        value={providerId}
                        onChange={e => setProviderId(e.target.value)}
                        required 
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="pubmatic-account-id">
                        <span className="text--secondary">PubMatic Account ID*</span>
                    </label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="pubmatic-account-id" 
                        placeholder="Enter the Pubmatic Account ID"
                        value={pubmaticAccountId}
                        onChange={e => setPubmaticAccountId(e.target.value)}
                        required 
                    />
                </div> */}

                <div className="form-group">
                    <label htmlFor="price">
                        <span className="text--secondary">Segment Price (USD)*</span>
                    </label>
                    <input 
                        type="number" 
                        className="form-control" 
                        id="price" 
                        placeholder="Enter the price in USD" 
                        step="0.01" 
                        min="0.1"
                        value={segmentPrice}
                        onChange={e => setSegmentPrice(e.target.value)}
                        required 
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="enabled" className="text--secondary">
                        Enabled
                    </label>
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="enabled"
                            checked={enabled}
                            onChange={e => setEnabled(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="enabled" id='text-label'>
                            Enable Segment
                        </label>
                    </div>
                </div>

                <div className="d-flex justify-content-end mb-4">
                    <button 
                        type="button" 
                        className="btn bg-white px-4 py-2 btn-cancel text--primary mr-2" 
                        onClick={handleCancel}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </button>
                    <button 
                        type="submit" 
                        className="btn bg--primary text--white py-2 px-4"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : (isEdit ? 'Update Segment' : 'Submit')}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SegmentForm;