import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import DatasetFilters from './SwitchTabs/CustomSegRuleFormFiles/DatasetFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Snackbar } from 'components';
import { pathFor } from 'constants/apiPaths';
import Request from 'services/Request';

const EquativForm = () => {
    const history = useHistory();
    const location = useLocation();
    const [segmentId, setSegmentId] = useState('');
    const [segmentName, setSegmentName] = useState('');
    const [segmentPrice, setSegmentPrice] = useState('');
    const [parentSegmentId, setParentSegmentId] = useState([]);
    const [categorizationId, setCategorizationId] = useState([]);
    const [isSelectable, setIsSelectable] = useState(false);
    const [isExclusion, setIsExclusion] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [messageQueue, setMessageQueue] = useState([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [showPrompt, setShowPrompt] = useState(true);

    // Detect edit mode
    const isEdit = history.location.pathname.includes("editEquativSegment");
    const equativSegmentRuleId = isEdit ? history.location.pathname.split('/').splice(5)[0] : null;

    useEffect(() => {
        const fetchSegmentData = async () => {
            if (isEdit && equativSegmentRuleId) {
                try {                    
                    const { statusCode, jsonResponse } = await Request(pathFor.listEquativSegments, {}, {}, 'GET');
                    
                    console.log('API response:', jsonResponse); // Check the full response
    
                    if (statusCode === 200 && jsonResponse) {
                        const segment = jsonResponse.segments.find(o => o._id === equativSegmentRuleId); // Ensure correct data structure
                        console.log('Segment found:', segment);
                        console.log('Fetching data for segment ID:', equativSegmentRuleId);
    
                        if (segment) {
                            setSegmentId(segment.SegmentId);
                            setSegmentName(segment.Name);
                            setSegmentPrice(segment.Price.toString());
                            setParentSegmentId([segment.ParentSegmentId]);
                            setCategorizationId([segment.categorizationID]);
                            setIsSelectable(segment.IsSelectable === 1);
                            setIsExclusion(segment.IsExclusion === 1);
                            setIsActive(segment.IsActive === 1);
                            setIsFormDirty(false); // Reset form dirty state after loading data
                            console.log(categorizationId,parentSegmentId);
                        } else {
                            setMessageQueue([{ error: 'Segment not found' }]);
                        }
                    } else {
                        setMessageQueue([{ error: 'Failed to fetch segment data' }]);
                    }
                } catch (error) {
                    console.error('Error fetching segment data:', error);
                    setMessageQueue([{ error: 'Error fetching segment data' }]);
                }
            }
        };
    
        fetchSegmentData();
    }, [isEdit, equativSegmentRuleId]);
    
    

    useEffect(() => {
        // Track form dirty state
        if (
            segmentId ||
            segmentName ||
            parseFloat(segmentPrice) !== 0 ||
            parentSegmentId ||
            categorizationId ||
            isSelectable ||
            isExclusion ||
            isActive
        ) {
            setIsFormDirty(true);
        } else {
            setIsFormDirty(false);
        }
    }, [
        segmentId,
        segmentName,
        segmentPrice,
        parentSegmentId,
        categorizationId,
        isSelectable,
        isExclusion,
        isActive,
    ]);

    const handleCancel = () => {
        if (!isFormDirty || window.confirm('Are you sure you want to leave? Your changes will be lost.')) {
            setShowPrompt(false); // Disable prompt before navigation
            history.goBack();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const payload = {
            SegmentId: segmentId,
            Name: segmentName,
            Description: '',
            Price: parseFloat(segmentPrice) || 0,
            ParentSegmentId: parentSegmentId ? parentSegmentId.toString() : null,
            categorizationID: categorizationId ? categorizationId.toString() : null,
            IsSelectable: isSelectable ? 1 : 0,
            IsExclusion: isExclusion ? 1 : 0,
            IsActive: isActive ? 1 : 0,
        };
    
        try {
            const { statusCode, jsonResponse } = isEdit
                ? await Request(pathFor.editEquativForm, payload)
                : await Request(pathFor.createEquativForm, payload);
    
            if (statusCode === 200 || statusCode === 201) {
                setIsFormDirty(false); // Reset form dirty state on successful submission
                setShowPrompt(false); // Disable the prompt
                setMessageQueue([{ success: isEdit ? 'Segment updated successfully!' : 'Segment created successfully!' }]);
                history.push('/dashboard/integrations/equativ'); // Navigate after resetting states
            } else if (statusCode === 422) {
                const firstError = Object.values(jsonResponse.errors)[0][0];
                setMessageQueue([{ error: firstError }]);
            } else {
                setMessageQueue([{ error: 'Server error. Try again later.' }]);
            }
        } catch (err) {
            setMessageQueue([{ error: 'Server error. Try again later.' }]);
        }
    };    

    return (
        <div className="px-4">
            <Prompt when={showPrompt && isFormDirty} message="Are you sure you want to leave? Your changes will be lost." />

            <div className="crumb bg--secondary w-100 py-2 px-3 rounded">
                <span className="mr-2">Create Segment </span>
                <FontAwesomeIcon icon={faCaretRight} className="caret-right" />
                <strong className="ml-2">{isEdit ? 'Edit Segment' : 'Add Segment'}</strong>
            </div>

            <p className="operation-table my-3">{isEdit ? 'EDIT SEGMENT' : 'ADD SEGMENT'}</p>

            {messageQueue.length > 0 && messageQueue.map((msg, index) => (
                <Snackbar key={index} msg={msg} />
            ))}


            <form className="col-xl-4 col-lg-5 col-md-6 col-sm-7 col-8 py-1 px-0" onSubmit={handleSubmit}>
                <div className="form-group" id="form-equativ" >
                    <input 
                        type="text" 
                        className="form-control" 
                        id="segment-id" 
                        placeholder="Enter the Segment ID" 
                        value={segmentId}
                        onChange={e => setSegmentId(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group" id="form-equativ">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="segment-name" 
                        placeholder="Enter the Segment Name"
                        value={segmentName}
                        onChange={e => setSegmentName(e.target.value)}
                        required 
                    />
                </div>

                <div className="form-group" id="form-equativ">
                    <input 
                        type="number" 
                        className="form-control" 
                        id="price" 
                        placeholder="Enter the price in USD" 
                        step=".01" 
                        min="0.1"
                        value={segmentPrice}
                        onChange={e => setSegmentPrice(e.target.value)}
                        required 
                    />
                </div>

                <DatasetFilters 
                    features={{
                        "Parent Segment Id": { 
                            selected: parentSegmentId, 
                            setSelected: setParentSegmentId,
                        },
                    }} 
                />

                <DatasetFilters 
                    features={{
                        "Categorization Id": { 
                            selected: categorizationId, 
                            setSelected: setCategorizationId, 
                        },
                    }} 
                />

                <div className="form-group" id="checkbox">
                    <div className="form-check text-secondary">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="isSelectable"
                            checked={isSelectable}
                            onChange={e => setIsSelectable(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="isSelectable" id='text-label'>
                            Selectable
                        </label>
                    </div>
                </div>

                <div className="form-group" id="checkbox">
                    <div className="form-check text-secondary">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="isExclusion"
                            checked={isExclusion}
                            onChange={e => setIsExclusion(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="isExclusion" id='text-label'>
                            Exclusion
                        </label>
                    </div>
                </div>

                <div className="form-group" id="checkbox">
                    <div className="form-check text-secondary">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="isActive"
                            checked={isActive}
                            onChange={e => setIsActive(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="isActive" id='text-label'>
                            Active
                        </label>
                    </div>
                </div>

                <div className="d-flex justify-content-end mb-4">
                    <button
                        type="button"
                        className="btn bg-white px-4 py-2 btn-cancel text--primary mr-2"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    <button type="submit" className="btn bg--primary text--white py-2 px-4">
                        {isEdit ? 'Update Segment' : 'Submit'}
                    </button>
                </div>
            </form>

           
        </div>
    );
};

export default EquativForm;