const pathFor = {
    usersStats: "/users-stats",
    datasetActivities: "/dataset-activities",
    advertiserDashboardStats: "/advertiser-dashboard-stats",
    ninaBotStats: "/nina-bot-stats",
    ninaBotPerformance: "/nina-bot-performance",
    insights: "/insights",
    allReports: "/all-reports",
    domainReport: "/domain-report",
    domainsList: "/domains-list",
    taxonomyReport: "/taxonomy-report",
    intentReport: "/intent-report",
    fetchCustomers: "/fetch-customers",
    customDatasetReport: "/custom-dataset-report",
    usersActivities: "/users-activities",
    inventoryDomains: "/inventory-domains",
    addDomains: "/add-domains",
    filterDomains: "/filter-domains",
    reCrawl: "/re-crawl",
    clientManagement: "/clients",
    addClient: "/add-client",
    editClient: "/edit-client",
    adminManagement: "/admins",
    addAdmin: "/add-admin",
    editAdmin: "/edit-admin",
    roles: "/roles",
    addRole: "/add-role",
    editRole: "/edit-role",
    pubmaticHome: "/dashboard/integrations/pubmatic",

    // APIs
    taxonomies: "/fetch-taxonomies",
    createPubmaticSegmentRules: "/integrations/pubmatic/segments/create",      // POST
    // createSegment: "/integrations/pubmatic/segments/create",                // POST
    customsegmentrules: "/integrations/pubmatic/segments",                     // GET
    // segments: "/integrations/pubmatic/segments",                            // GET
    editCustomSegmentRules: "/integrations/pubmatic/segments/edit",            // EDIT
    // editSegmentRules: "/integrations/pubmatic/segments/edit",
    createEquativForm: "/integrations/equativ/segments/create",
    editEquativForm: "/integrations/equativ/segments/edit",
    publishEquativForm: "/integrations/equativ/segments/publish",
    listEquativSegments: "/integrations/equativ/segments",
    createPubMaticExportJob: "/integrations/pubmatic/export-jobs/create",
    pubmaticExportJobs: "/integrations/pubmatic/export-jobs",                   // GET
    pubmaticDomainsWhitelist: "/integrations/pubmatic/domains-whitelist",
    createPubmaticDomainsWhitelist: "/integrations/pubmatic/domains-whitelist/create",
    editPubmaticDomainsWhitelist: "/integrations/pubmatic/domains-whitelist/edit",
    pubmaticURLsWhitelist: "/integrations/pubmatic/urls-whitelist",
    createPubmaticURLsWhitelist: "/integrations/pubmatic/urls-whitelist/create",
    editPubmaticURLsWhitelist: "/integrations/pubmatic/urls-whitelist/edit",

}

export { pathFor }
