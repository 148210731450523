import React, { useState } from 'react';
import { AltDropDown } from './Components/DropDown';
import { intentMappings, languageMappings } from './mappings';
import { capitalise } from 'utils/common';

function DatasetFilters(props) {
    const { features, className } = props;

    const featureOptions = {
        Language: Object.keys(languageMappings),
        Candidate: ["Donald Trump", "Joe Biden"],
        "Liberal Conservative": ["Liberal", "Conservative"],
        "Sensational Fact Based": ["Sensationalist", "Fact-based"],
        "Brand Safety": ["Consumption of Drugs", "Content with Offensive Terms", "Death and Injury", "Guns and Weapons", 
                        "Hate Speech","Mature and Sexual Content", "Serious, Sex, and Violent Crime", "Terrorism", "Tobacco", 
                        "War and Conflicts","COVID-19"],
        "Parent Segment Id":["Consumption of Drugs", "Content with Offensive Terms", "Death and Injury"],  
        "Categorization Id":["Serious, Sex, and Violent Crime", "Terrorism", "Tobacco"],   
        Sentiment: ["Positive", "Negative"],
        Intent: Object.keys(intentMappings),  
    };

    // Function to render either input or dropdown based on feature config
    const renderField = (key, value, idx) => {
    if (value.type === "checkbox") {
        return (
            <div className="restrict">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id={key}
                    checked={value.selected || false}
                    onChange={(e) => value.setSelected(e.target.checked)}
                    disabled={value.disabled}
                />
                <label className="form-check-label" htmlFor={key}>
                    {key}
                </label>
            </div>
        );
    } 

    if (value.inputField) {
        return (
            <input
                type={value.type || "text"} // Use "text" as default type
                className="border py-2 px-3 w-100 rounded"
                placeholder={value.placeholder || `Enter ${key}`}
                value={value.selected || ''}
                onChange={(e) => value.setSelected(e.target.value)}
                disabled={value.disabled}
                step={value.step}
                min={value.min}
            />
        );
    }

    return (
        <AltDropDown
            className="border py-2 w-100 d-flex justify-content-between align-items-center"
            label={capitalise(key)}
            placeholder={`Select ${key}${value.multi ? "s" : ""}`}
            options={value.options || featureOptions[key]}
            disabled={value.disabled}
            {...value}
        />
    );
};


    return (
        <div className={className || "row"}>
            {
                Object.entries(features).map(([k, v], idx) => (
                    <div key={idx} className='col mb-responsive d-flex'>
                        <div className='flex-grow-1'>
                            {renderField(k, v, idx)}
                        </div>
                        {
                            props.children && (idx === Object.keys(features).length - 1)
                                ? <div className='ml-3'>{props.children}</div>
                                : <></>
                        }
                    </div>
                ))
            }
        </div>
    );
}

export default DatasetFilters;