import React from 'react'

function PageLength(props) {
    const { pageLength, setPageLength, pageLengthOptions } = props
    const lengthOptions = pageLengthOptions || [10, 25, 50, 100]

    return (
        <select data-testid="pagelength-dropdown" className="page-length" onChange={e => setPageLength(e.target.value) }>
            {
                lengthOptions.map((opt, idx) => (
                    <option key={ idx } data-testid={ `option-${idx}` } value={ opt } selected={ pageLength === opt }>{ opt }</option>
                ))
            }
        </select>
    )
}

export default PageLength
