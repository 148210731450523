import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

function DateRangesPicker(props) {
    const {setPayload, currentValue, includeTime = false} = props
    const [btnText, setBtnText] = useState(currentValue && "date-start" in currentValue
                                                ? `${currentValue['date-start']} to ${currentValue['date-end']}`
                                                : "Select date range"
                                            )
    const defaultUtcMoment = moment.utc

    useEffect(() => {
        setBtnText(currentValue && "date-start" in currentValue
                        ? `${currentValue['date-start']} to ${currentValue['date-end']}`
                        : "Select date range")
    }, [currentValue])

    const handleRange = (start, end) => {
        // if (!includeTime) {
        //     start = moment(start).startOf('day')
        //     end = moment(end).endOf('day')
        // }
        if(includeTime){
            const dateStart = start.add(1, 'days').toISOString()
            const dateEnd = end.toISOString()
            const payload = {
                'date-start': dateStart,
                'date-end': dateEnd,
            }
            setBtnText(`${dateStart} to ${dateEnd}`)
            setPayload(payload)
        } else {
            const dateStart = start.add(1, 'days').toISOString().split('T')[0]
            const dateEnd = end.toISOString().split('T')[0]
            const payload = {
                'date-start': dateStart,
                'date-end': dateEnd,
            }
            setBtnText(`${dateStart} to ${dateEnd}`)
            setPayload(payload)
        }
    }

    return (
        <>
            <DateRangePicker
                onCallback={ handleRange }
                initialSettings={{
                    minDate: '1/1/2020',
                    maxDate: defaultUtcMoment().format('MM/DD/YYYY'),
                    alwaysShowCalendars: true,
                    ranges: {
                       'Last day': [defaultUtcMoment().subtract(1, 'days'), defaultUtcMoment()],
                       'Last 7 Days': [defaultUtcMoment().subtract(6, 'days'), defaultUtcMoment()],
                       'Last 14 Days': [defaultUtcMoment().subtract(13, 'days'), defaultUtcMoment()],
                       'Last 28 Days': [defaultUtcMoment().subtract(27, 'days'), defaultUtcMoment()],
                       'MTD': [defaultUtcMoment().startOf('month'), defaultUtcMoment()],
                    }
                }}
            >
                <button type="button" className="btn btn-white w-100">
                    <div className="d-flex justify-content-between align-items-center">
                        { btnText }
                        <FontAwesomeIcon icon={ faCaretDown } size="sm" />
                    </div>
                </button>
            </DateRangePicker>
        </>
    )
}

export default DateRangesPicker
