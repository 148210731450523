import React, { useEffect, useState } from 'react'
import DomainsTable from './DomainsTable'
import DataTableControllers from './DataTableControllers'
import Request from 'services/Request'
import useSnackbar from './Components/useSnackbar'
import { pathFor } from 'constants/apiPaths'


function PublisherDomains(props) {
    const setMessage = useSnackbar()
    const { setExtras, org_type, dashboardType } = props
    const [ domains, setDomains ] = useState([])
    const [ selectedDomains, setSelectedDomains ] = useState([])
    const [ tableBody, setTableBody ] = useState([])

    const { organisation_id } = JSON.parse(sessionStorage.getItem("currentOrg"))

    const handleSelect = domain => {
        selectedDomains.includes(domain) 
            ? setSelectedDomains(selectedDomains.filter(item => item !== domain))
            : setSelectedDomains([ domain, ...selectedDomains ])
    }
    
    useEffect(async () => {
        try {
            const queryParams = org_type === "internal" ? `?dashboard=${dashboardType}` : ""
            const { statusCode, jsonResponse } = await Request(`${pathFor.FetchStoredDomains}${queryParams}`, { organisation_id })
            if (statusCode === 200) {
                setDomains(jsonResponse.data)
                setSelectedDomains([])
            } else setMessage(jsonResponse)
        } catch (error) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }, [])

    const domainsTableProps = { domains: tableBody, handleSelect, selectedDomains, setSelectedDomains, className: "mt-2 border rounded", editable: true, _10rows: true }
    
    useEffect(() => {
        if (selectedDomains?.length) {
            setExtras({domains_whitelist: selectedDomains})
        } else setExtras({})
    }, [ selectedDomains ])

    return (
        <DataTableControllers records={ domains } setTableBody={ setTableBody }>
            <DomainsTable { ...domainsTableProps } />
        </DataTableControllers>
    )
}

export default PublisherDomains