import React from 'react';
import operationsConstants from 'constants/operationsConstants'
import integrationConstants from 'constants/integrationConstants';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import PageLayout from './PageLayout';

function Routes(props) {
    const match = useRouteMatch()
    const { pages } = props
    const routes = [];

    pages.forEach(item => {
        if (item.subMenu) {
            item.subMenu.forEach(sub => routes.push(
                <Route exact path={ `${match.path}${item.path + sub.path}` }>
                    <PageLayout item={ sub } />
                </Route>
            ))
            routes.push(
                <Redirect exact from={ `${match.path}${item.path}` } to={ `${match.path}${item.path + item.subMenu[0].path}` } />
            )
        } else {
            routes.push(
                <Route exact path={ `${match.path}${item.path}` }>
                    <PageLayout item={ item } />
                </Route>        
            )
        }
    })
    operationsConstants.forEach(item => {
        routes.push(
            <Route path={ `${match.path}${item.path}` }>
                <PageLayout item={ item } />
            </Route> 
        )
    })

    integrationConstants.forEach(item => {
        routes.push(
            <Route path={ `${match.path}${item.path}` }>
                <PageLayout item={ item } />
            </Route> 
        )
    })


    return (
        <Switch>
            { routes }
            <Redirect to={ routes[0].props.path } />
        </Switch>
    )
}

export default Routes;