import {DataTable} from "../../../components";
import {
    pubmaticURLsWhitelistHead
} from "../../../constants/tableConstants";
import React, {useEffect} from "react";
import Request from '../../../services/Request'
import {pathFor} from "../../../constants/apiPaths";
import {useHistory} from "react-router-dom";

function PubmaticURLsWhitelist(props) {
    const history = useHistory();
    const {setMessageQueue} = props
    const [URLsWhitelist, setURLsWhitelist] = React.useState([])


    const fetchURLsWhitelist = async () => {
        try {
            const {statusCode, jsonResponse} = await Request(pathFor.pubmaticURLsWhitelist, {}, {}, "GET")
            if (statusCode === 200) {
                const {global_urls_whitelist} = jsonResponse
                setURLsWhitelist(global_urls_whitelist)
            } else if (statusCode === 401) {
                setMessageQueue([jsonResponse])
            } else {
                setMessageQueue([{"error": "Unable to fetch data."}])
            }
        } catch (err) {
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }

    useEffect(async () => {
        await fetchURLsWhitelist()
    }, [])

    return (
        <div>
            <div className="d-flex mb-3 justify-content-between">
                <p className="operation-table">All URLs Whitelist</p>
                <span className="d-flex">
                    <button
                        type="button"
                        className="btn bg--primary text--white px-3 ml-3"
                        onClick={() => history.push(history.location.pathname + '/createURLsWhitelist')}
                    >
                        Create URLs Whitelist
                    </button>
                </span>
            </div>
            <DataTable tableData={{tableHead: pubmaticURLsWhitelistHead, tableBody: URLsWhitelist}}
                       infoHead={true}
                       setMessageQueue={setMessageQueue}/>
        </div>
    )
}

export default PubmaticURLsWhitelist
