import React from 'react';

function Btn(props) {
    const { variant, className, onClick, type } = props;
    const bgVariant = variant || "-primary";
    const textVariant = bgVariant === "-grey" ? "-primary" : "white";

    const handleClick = (event) => {
        // If the button is not of type 'submit', prevent form submission
        if (type !== 'submit') {
            event.preventDefault();
        }
        // Call any onClick handler passed from props
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <button
            {...props}
            onClick={handleClick}
            className={`${className} border-0 rounded bg-${bgVariant} text-${textVariant} py-2 px-3 font-weight-bold`}
        >
            {props.children}
        </button>
    );
}

export default Btn;
