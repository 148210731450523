import { faCheckSquare, faChevronDown, faChevronRight, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

function TaxonomyItem(props) {
    const { dropDownItem, taxonomies, setTaxonomies, idx } = props;
    const [open, setOpen] = useState(false);
    const [parentChecked, setParentChecked] = useState(null);
    const [selected, setSelected] = useState([]);
    const [hovered, setHovered] = useState("");

    const hoverClass = "bg--grey text--primary rounded pointer";
    const textValue = dropDownItem[0];

    // Initialize selected items on component mount and when taxonomies change
    useEffect(() => {
        const initialSelected = dropDownItem[1].filter(item => {
            const taxonomyKey = `${textValue}_${item}`;
            return taxonomies.includes(taxonomyKey);
        });
        
        setSelected(initialSelected);
        
        // Set parent checked state based on selected items
        if (initialSelected.length === dropDownItem[1].length) {
            setParentChecked(true);
        } else if (initialSelected.length > 0) {
            setParentChecked(null);
        } else {
            setParentChecked(false);
        }
    }, [taxonomies, textValue, dropDownItem]);

    const handleSelection = (item) => {
        const taxonomy = `${textValue}_${item}`;
        let newTaxonomies;
        let newSelected;

        if (taxonomies.includes(taxonomy)) {
            newTaxonomies = taxonomies.filter(i => i !== taxonomy);
            newSelected = selected.filter(i => i !== item);
        } else {
            newTaxonomies = [...taxonomies, taxonomy];
            newSelected = [...selected, item];
        }

        setTaxonomies(newTaxonomies);
        setSelected(newSelected);

        // Update parent checked state
        if (newSelected.length === dropDownItem[1].length) {
            setParentChecked(true);
        } else if (newSelected.length === 0) {
            setParentChecked(false);
        } else {
            setParentChecked(null);
        }
    };

    const handleParentSelection = () => {
        const newParentChecked = !parentChecked;
        setParentChecked(newParentChecked);

        if (newParentChecked) {
            // Select all items
            const allTaxonomies = dropDownItem[1].map(item => `${textValue}_${item}`);
            const newTaxonomies = [...new Set([...taxonomies, ...allTaxonomies])];
            setTaxonomies(newTaxonomies);
            setSelected(dropDownItem[1]);
        } else {
            // Deselect all items
            const filteredTaxonomies = taxonomies.filter(
                item => !dropDownItem[1].map(subItem => `${textValue}_${subItem}`).includes(item)
            );
            setTaxonomies(filteredTaxonomies);
            setSelected([]);
        }
    };

    return (
        <div className='px-3'>
            <div className={`d-flex align-items-center ${idx && "border-top"} py-1`}>
                <div className='chevron-container'>
                    <FontAwesomeIcon 
                        icon={open ? faChevronDown : faChevronRight} 
                        size="sm" 
                        className='text--secondary pointer' 
                        onClick={() => setOpen(!open)} 
                    />
                </div>
                <div 
                    onClick={handleParentSelection}
                    onMouseEnter={() => setHovered(textValue)}
                    onMouseLeave={() => setHovered("")}
                    className={`d-flex align-items-center ${hovered === textValue && hoverClass} w-100 p-1`}
                >
                    <div className='mx-2 checkbox-container bg-white'>
                        {selected.length > 0 && selected.length < dropDownItem[1].length ? (
                            <FontAwesomeIcon icon={faMinusSquare} size="lg" className='text--primary' />
                        ) : parentChecked || selected.length === dropDownItem[1].length ? (
                            <FontAwesomeIcon icon={faCheckSquare} size="lg" className='text--primary' />
                        ) : (
                            <div className=''></div>
                        )}
                    </div>
                    <span className='ml-1'>{textValue}</span>
                </div>
            </div>
            {open && (
                <div>
                    {dropDownItem[1].map(item => (
                        <div 
                            key={item}
                            onClick={() => handleSelection(item)}
                            onMouseEnter={() => setHovered(`_${item}`)}
                            onMouseLeave={() => setHovered("")}
                            className={`d-flex align-items-center ${hovered === "_" + item && hoverClass} w-90 p-1 mx-4 border-top`}
                        >
                            <div className='mx-3 bg-white checkbox-container'>
                                {selected.includes(item) ? (
                                    <FontAwesomeIcon icon={faCheckSquare} size="lg" className='text--primary' />
                                ) : (
                                    <div className=''></div>
                                )}
                            </div>
                            <span>{item}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default TaxonomyItem;